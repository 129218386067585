import maintenance from './under_maintenance.png';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={maintenance} className="responsive" alt="logo"/>
                <p>
                    Meanwhile, visit our site or play our game!
                </p>
                <a
                    className="App-link"
                    href="https://play.kephi.io"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Play and earn
                </a>
            </header>
        </div>
    );
}

export default App;
